import { RimRenovationTypeOption } from '@/models/RimRenovation/RimRenovation'

export interface countryBasedRenovationTypes {
  se: RimRenovationTypeOption[]
  no: RimRenovationTypeOption[]
}

export const renovationTypes = {
  se: [
    {
      value: 'helrenovering',
      name: 'Helrenovering',
      description:
        'Blästring av klarlack och färg, skadereparation och våt- och pulverlackering av hela fälgen. Diamond cut svarvas vid behov och hela fälgen ges ny klarlack.',
    },
  ],
  no: [
    {
      value: 'helrenovering',
      name: 'Helrenovering',
      description:
        'Miljøvennlig fornying av felgen. Eksisterende overflatebehandling fjernes, skaden repareres og det utføres 3-trinns '
        + 'pulverlakkering av hele felgen med fargematch. Felger med blank framside maskineres som originalt, før hele felgen '
        + 'forsegles med klarlakk. Oppretting av urund felg i forbindelse med lakkering er inkludert i prisen. Prisen påvirkes av '
        + 'felgenes størrelse og ønsket leveringstid.',
    },
  ],
} as countryBasedRenovationTypes
