import userMonitoring, { ErrorTypes } from '@/mixins/userMonitoring'

export const getGoogleMapImageRequest = (
  latitude: string,
  longitude: string,
  branchName: string
): Promise<string> => {
  return fetch(
    `https://maps.googleapis.com/maps/api/staticmap?
	center=${latitude},${longitude}&zoom=12
	&size=640x257&scale=2
	&markers=color:0xF7941E%7Clabel:${branchName}%7C${latitude},${longitude}
  &key=${(window as any).VITE_GoogleApiKey}`
  )
    .then((response) => response.url)
    .catch((error) => {
      userMonitoring().trackError(
        `${ErrorTypes.google}: https://maps.googleapis.com/maps/api/staticmap failed`,
        error
      )
      return ''
    })
}
