import { PriceList } from '@/models/RimRenovation/RimRenovation'

export interface countryBasedPriceList {
  se: PriceList
  no: PriceList
}

export const priceList = {
  se: {
    lackad: {
      basrenovering: {},
      helrenovering: {
        17: 2395,
        18: 2695,
        19: 2695,
        20: 2695,
        21: 3095,
        22: 3095,
        23: 3095,
        24: 3095,
      },
    },
    svarvad: {
      basrenovering: {},
      helrenovering: {
        17: 2995,
        18: 3295,
        19: 3295,
        20: 3295,
        21: 3695,
        22: 3695,
        23: 3695,
        24: 3695,
      },
    },
  },
  no: {
    lackad: {
      basrenovering: {},
      helrenovering: {
        17: 2964,
        18: 3134,
        19: 3389,
        20: 3644,
        21: 3814,
        22: 3984,
        23: 3984,
        24: 3984,
      },
    },
    svarvad: {
      basrenovering: {},
      helrenovering: {
        17: 3559,
        18: 3729,
        19: 3984,
        20: 4154,
        21: 4409,
        22: 4579,
        23: 4579,
        24: 4579,
      },
    },
  },
} as countryBasedPriceList
