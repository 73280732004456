import { BookingInformation } from '@/models/Book/BookingInformation'
import { PreliminaryBookingError } from '@/models/Calendar/PreliminaryBookingError'
import { defineStore } from 'pinia'
import { EventBus } from '@/event-bus'
import { createPreliminaryBookingByGraphQL } from '@/api/booking.service'
import { Branch } from '@/models/Branch/Branch'
import { useSettings } from './settings.store'
import Container from '@/models/Book/Container'
import { useBooking } from './booking.store'
import userMonitoring, { ErrorTypes } from '@/mixins/userMonitoring'
import { useConfigCat } from './config-cat.store'
import { useVehicle } from './vehicle.store'

interface PreliminaryBookingState {
  preliminaryBooking: BookingInformation
  isLoading: boolean
  isDoubleBooking: boolean
  isError: boolean
  isValid: boolean
  isDisabled: boolean
  isBooked: boolean
  message: string
  reservedTime: number | undefined
  error: PreliminaryBookingError
  useBookmakerPreliminarybooking: boolean
}

export const usePreliminaryBooking = defineStore('preliminaryBooking', {
  state: () =>
    ({
      preliminaryBooking: {} as BookingInformation,
      isLoading: false,
      isValid: false,
      isDisabled: true,
      isError: false,
      isDoubleBooking: false,
      isBooked: false,
      message: '',
      reservedTime: undefined,
      error: {} as PreliminaryBookingError,
      useBookmakerPreliminarybooking: false,
    } as PreliminaryBookingState),
  actions: {
    bookingCreated(): void {
      this.isLoading = false
      this.isValid = true
      EventBus.emit('reservation-is-created')
    },

    isSameDateAndTime(date1: string, date2: string): boolean {
      const currentDate = new Date(date1)
      const newDate = new Date(date2)
      return currentDate.getTime() === newDate.getTime()
    },

    hasEqualPreliminaryBooking(regionId: number, containerId: number, newDate: string) {
      if (this.preliminaryBooking) {
        if (!containerId) {
          return false
        }
        const preliminaryBooking = this.preliminaryBooking
        return (
          preliminaryBooking.BookedTechnician && // Only check if technician exists since containerId can be a new one for slot bookings
          preliminaryBooking.BookedRegion === regionId &&
          this.isSameDateAndTime(this.preliminaryBooking.PreliminaryBookedDate, newDate)
        )
      }
      return false
    },

    loadPreliminarybookingVersion() {
      useConfigCat()
        .getFeatureFlag('useBookmakerPreliminarybooking')
        .then((status: boolean) => {
          this.$state.useBookmakerPreliminarybooking = status
        })
    },

    async createPreliminaryBooking(
      regionId: number,
      containerId: number,
      selectedDate: string,
      container: Container | undefined
    ): Promise<void> {
      const bookingStore = useBooking()
      this.isDisabled = true
      EventBus.emit('reservation-is-started')
      this.isLoading = true
      const settingsStore = useSettings()
      const vehicleStore = useVehicle()
      if (this.hasEqualPreliminaryBooking(regionId, containerId, selectedDate)) {
        this.bookingCreated()
      } else {
        const queryVariables = {
          ContainerId: containerId ?? -1,
          ServiceId: bookingStore.workshopService?.id,
          Date: selectedDate,
          RegionCode: regionId,
          IsTireHotel: vehicleStore.hasTireHotel(),
          SiteId: settingsStore.siteData.siteId,
          branchId: bookingStore.selectedBranch?.id ?? '',
        }
        await createPreliminaryBookingByGraphQL(queryVariables)
          .then((response) => {
            switch (response.__typename) {
              case 'StatusCodeResponse':
                if (response.statusCode === 204) {
                  userMonitoring().trackAction(`CALENDAR - double booking`)
                  ;(this.isDoubleBooking = true), (this.isDisabled = false)
                } else if (response.statusCode !== 200) {
                  this.isError = true
                  this.message = response.message
                    ? response.statusCode + ' - ' + response.message
                    : ''
                }
                break
              case 'PreliminaryBookingResponse':
                this.preliminaryBooking = {
                  ...this.preliminaryBooking,
                  SelectedDate: bookingStore.SelectedDate.date,
                  Branch: bookingStore.selectedBranch as Branch,
                  Container: container,
                  BookedRegion: queryVariables.RegionCode,
                  BookedTechnician: queryVariables.ContainerId,
                  PreliminaryBookingId: response.bookingId as string,
                  PreliminaryBookedDate: queryVariables.Date,
                }
                this.reservedTime = response.reservedTime
                this.bookingCreated()
                break
              default:
                ;(this.isError = true),
                  (this.message = '[No matching case for typename in switch statement]'),
                  (this.isValid = false),
                  (this.isLoading = false),
                  userMonitoring().trackError('Create preliminary booking failed', {
                    request: queryVariables,
                    response: response,
                  })
            }
          })
          .catch((error: Error) => {
            this.isError = true
            this.isLoading = false
            this.message = '[Något gick fel]'
            userMonitoring().trackError('Create preliminary booking failed', {
              request: queryVariables,
              error: error,
            })
          })
        if (containerId == 0 || regionId == 0) {
          userMonitoring().trackError(
            `${ErrorTypes.faultyData}: containerId failed: }` /*${JSON.stringify(request)}*/
          )
        }
      }
    },

    clearPreliminaryBooking(): void {
      ;(this.preliminaryBooking = {} as BookingInformation), (this.isLoading = false)
      ;(this.isValid = false),
        (this.isDisabled = true),
        (this.isError = false),
        (this.isDoubleBooking = false),
        (this.message = ''),
        (this.error = {} as PreliminaryBookingError)
    },
  },
})
